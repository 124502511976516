<template>
  <div
    class="gps-page3"
    style="background: #fff; padding: 10px; min-height: 100%; height: auto"
  >
    <div
      class="gps-body"
      style="height: calc(100% - 20px); border-radius: 10px"
    >
      <gps-tabs-form
        :formArray="formArray"
        :tabFirstName="tabFirstName"
        :row="formRow"
        @saveEvent="saveEvent"
      ></gps-tabs-form>
    </div>
  </div>
</template>
<script setup>
import {
  reactive,
  getCurrentInstance,
  onMounted,
  ref,
  toRaw,
  markRaw,
} from "vue";
import { useI18n } from "vue-i18n";
import GpsTabsForm from "./components/GpsTabsForm.vue";
import { ElMessage } from "element-plus";
import refresh_table from "../../utils/refrshTable";
//引用区域
const { t } = useI18n();
let { proxy } = getCurrentInstance();
//声明变量
const formRow = ref({});

const formFieldsArray = [
  {
    label: t("xtpz.dtsz"),
    name: "addr",
    children: [
      {
        type: "radio",
        label: t("xtpz.mrdt"),
        field: "default_map",
        placeholder: t("xtpz.mrdt"),
        val: "1",
        required: false,
        data: [
          {
            value: "1",
            name: t("xtpz.ge"),
          },
          {
            value: "2",
            name: t("xtpz.bd"),
          },
          {
            value: "3",
            name: t("xtpz.osm"),
          },
        ],
      },
      {
        type: "pwd",
        label: t("xtpz.gekey"),
        field: "googleKey",
        placeholder: t("xtpz.gekey"),
        val: "",
        required: false,
      },
      {
        type: "pwd",
        label: t("xtpz.bdkey"),
        field: "baiduKey",
        placeholder: t("xtpz.bdkey"),
        val: "",
        required: false,
      },
      // {
      //   type: "ipt",
      //   label: t("xtpz.maxCar"),
      //   field: "BaiduMaxCarCounts",
      //   placeholder: t("xtpz.maxCar"),
      //   val: "",
      //   required: false,
      // },
      // {
      //   type: "radio",
      //   label: t("xtpz.zdhqwz"),
      //   field: "autoGetAddress",
      //   placeholder: t("xtpz.zdhqwz"),
      //   val: "1",
      //   required: false,
      //   data: [
      //     {
      //       value: "1",
      //       name: t("xtpz.kq"),
      //     },
      //     {
      //       value: "2",
      //       name: t("xtpz.gb"),
      //     },
      //   ],
      // },
      // {
      //   type: "radio",
      //   label: t("xtpz.sytx"),
      //   field: "autoGetAudio",
      //   placeholder: t("xtpz.sytx"),
      //   val: "1",
      //   required: false,
      //   data: [
      //     {
      //       value: "1",
      //       name: t("xtpz.kq"),
      //     },
      //     {
      //       value: "2",
      //       name: t("xtpz.gb"),
      //     },
      //   ],
      // },
    ],
  },
  {
    label: t("xtpz.dwsz"),
    name: "unit",
    children: [
      {
        type: "radio",
        label: t("xtpz.sddw"),
        field: "SpeedUnit",
        placeholder: t("xtpz.sddw"),
        val: "1",
        required: false,
        data: [
          {
            value: "1",
            name: "KM/H",
          },
          {
            value: "2",
            name: "MPH",
          },
        ],
      },
    ],
  },
  {
    label: t("xtpz.spsz"),
    name: "video",
    children: [
      {
        type: "ipt",
        label: t("xtpz.gbsj"),
        field: "videoTime",
        placeholder: t("xtpz.gbsj"),
        val: "",
        required: false,
      },
    ],
  },
];

const formArray = reactive(formFieldsArray);
const tabFirstName = ref("addr");

//初始化数据
proxy.$api.sys_get_xtpz({}).then((res) => {
  let row = res.data.row;
  formRow.value = row;
});
////////////////////////////////

const saveEvent = (temp) => {
  // console.log(toRaw(temp));
  // temp.map_default_view = JSON.stringify(temp.map_default_view);
  // temp.SMTP = JSON.stringify(temp.SMTP);
  // temp.SMTP = "";
  // temp.map_default_view = "";
  proxy.$api.sys_save_xtpz(markRaw(temp)).then((res) => {
    ElMessage({
      message: t("commKey.MSG_" + res.data.msg_type),
      type: "success",
    });
    // if (res.data.msg_type == 1 || res.data.msg_type == 2) {
    //   refAnyCom.value.dialogVisible = false;
    //   getList();
    // }
    refresh_table("gps_usersconfig", 5);
    location.reload();
  });
};
</script>
<style>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
.gps-page3 {
  background: #fff !important;
  padding: 10px;
  height: 100%;
  border: 1px solid #ccc;
}
::v-deep .gps-body {
  background: #fff !important;
}
</style>
